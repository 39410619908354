import { NJCard, NJCardBody } from '@engie-group/fluid-design-system-react'
import type { DataScienceChallengeHome } from 'types/datascience-home'
import type { FC } from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
interface DSCHomeProps {
  home: DataScienceChallengeHome
}

const DSCHome: FC<DSCHomeProps> = ({ home }) => {
  const { page_title: title, page_content } = home.data.attributes
  console.info(page_content)
  return (
    <div
      {...{
        className: 'dsc-container'
      }}
    >
      <NJCard>
        <NJCardBody {...{ title }}>
          <Markdown
            {...{
              children: page_content,
              components: {
                img(props) {
                  const { alt, src } = props
                  return <img alt={alt} src={src} />
                }
              },
              esacapeHtml: false,
              rehypePlugins: [rehypeRaw]
            }}
          />
        </NJCardBody>
      </NJCard>
    </div>
  )
}

export { DSCHome }
